// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/gatsby';

const SENTRY_DSN = process.env.SENTRY_DSN;
const currentEnv = (process.env.ENV_NAME || 'dev').toLowerCase();

const getEnvLabel = () => {
  if (currentEnv === 'prod') {
    return 'production';
  }
  if (currentEnv === 'dev') {
    return 'development';
  }
  return currentEnv;
};

if (['dev', 'uat', 'prod'].includes(currentEnv)) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: getEnvLabel(),
    integrations: [
      // Need to change to Sentry.replayIntegration when updated
      new Sentry.Replay({
        blockAllMedia: false,
        maskAllText: false,
        maskAllInputs: true
      })
    ],
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.2,
    sampleRate: 0.2,
    // Sets replay session sample rate to 0% as we want to focus on errors only with limited space
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    // TODO: If sentry is properly activated in summerset, we need to address the following - Sentry [Info]: Not uploading source maps due to missing SENTRY_ORG and SENTRY_PROJECT env variables.
    beforeSendTransaction: (event) => {
      if (
        event.request?.headers?.['user-agent'] ===
        'Maker.tech Custom Algolia Crawler'
      ) {
        return null;
      }
      return event;
    },
    ignoreErrors: [
      'Invariant: attempted to hard navigate to the same URL',
      'possibly because the user denied permission',
      'Error: Extension context invalidated.',
      'Heatmap Settings not available',
      'ResizeObserver loop limit exceeded',
      "Can't find variable: IntersectionObserver", // iOS 8
      'IntersectionObserver is not defined',
      "'IntersectionObserver' is undefined", // Edge 14
      'yourir 1.12.6 is already loaded, ignoring 1.12.6', // investor graph script
      'document.getElementsByClassName.ToString is not a function', // Samsung Internet 7.2 https://github.com/SamsungInternet/support/issues/56
      'Object Not Found Matching Id', // https://github.com/getsentry/sentry-javascript/issues/3440
      'Blocked a frame with origin' // fls.doubleclick.net - https://github.com/getsentry/sentry/issues/5267
    ],
    beforeSend: (event, hint) => {
      // hide recaptcha errors - Non-Error promise rejection captured with value: Timeout
      // https://github.com/getsentry/sentry-javascript/issues/2514
      // https://sentry.io/organizations/maker-9m/issues/2265675121/?project=5667372&query=is%3Aunresolved&sort=priority&statsPeriod=14d
      if (
        hint.originalException === 'Timeout' ||
        hint.originalException === 'Timeout (n)'
      ) {
        return null;
      }
      return event;
    }
  });
}
