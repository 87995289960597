/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// Replace Gatsby's rehydrate function to resolve
// inconsistent SSR classNames
// https://github.com/gatsbyjs/gatsby/discussions/17914
// const ReactDOM = require('react-dom');

// export function replaceHydrateFunction() {
//   return (element, container, callback) => {
//     ReactDOM.render(element, container, callback);
//   };
// }

require('./src/styles/font.css');

exports.onClientEntry = () => {
  if (typeof window.ResizeObserver === `undefined`) {
    window.ResizeObserver = require('resize-observer-polyfill').default;
  }
  // Uncomment the below to enable Why did you render. Keywords for IDE text search: wdyr | why-did-you-render | WDYR | WHY-DID-YOU-RENDER
  // if (process.env.NODE_ENV !== 'production') {
  //   const React = require('react');
  //   const whyDidYouRender = require('@welldone-software/why-did-you-render');
  //   whyDidYouRender(React, {
  //     trackAllPureComponents: true
  //   });
  // }
};

exports.onRouteUpdate = () => {
  // TODO: this feels buggy / hacky. Shouldn't we be able to access route props in the form?
  // capture referrer URL, used for forms to supply data to the pageOrigin field
  window.locations = window.locations || [document.referrer];
  window.locations.push(window.location.href);
  window.previousPath = window.locations[window.locations.length - 2];

  return false;
};

/**
 * Disable core prefetching because Gatsby SPA is currently not working - need to investigate
 * Once SPA is working correctly then corePrefetching should be turned on
 */
exports.disableCorePrefetching = () => true;
